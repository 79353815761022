@tailwind base;
@tailwind components;
@tailwind utilities;

//@import '~ladda/dist/ladda-themeless.min.css';

@import '~ladda/dist/ladda.min.css';
@import '~@aws-amplify/ui/dist/styles.css';
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@import '~video.js/dist/video-js.css';

:root,
[data-amplify-theme] {
  --amplify-colors-primary-10: theme('colors.primary.10'); // Light Indigo
  --amplify-colors-primary-20: theme('colors.primary.20'); // Indigo
  --amplify-colors-primary-40: theme('colors.primary.40'); // Dark Indigo
  --amplify-colors-primary-60: theme('colors.primary.60'); // Dark Indigo
  --amplify-colors-primary-80: theme('colors.primary.80'); // Dark Indigo
  --amplify-colors-primary-90: theme('colors.primary.90'); // Dark Indigo
  --amplify-colors-primary-100: theme('colors.primary.100'); // Indigo

  --amplify-colors-secondary-10: theme('colors.secondary.10'); // Lightest shade
  --amplify-colors-secondary-20: theme('colors.secondary.20'); // Light shade
  --amplify-colors-secondary-40: theme('colors.secondary.40'); // Medium light shade
  --amplify-colors-secondary-60: theme('colors.secondary.60'); // Medium shade
  --amplify-colors-secondary-80: theme('colors.secondary.80'); // Medium dark shade
  --amplify-colors-secondary-90: theme('colors.secondary.90'); // Dark shade
  --amplify-colors-secondary-100: theme('colors.secondary.100'); // Darkest shade

  --amplify-colors-neutral-10: theme('colors.neutral.10'); // Lightest shade
  --amplify-colors-neutral-20: theme('colors.neutral.20'); // Light shade
  --amplify-colors-neutral-40: theme('colors.neutral.40'); // Medium light shade
  --amplify-colors-neutral-60: theme('colors.neutral.60'); // Medium shade
  --amplify-colors-neutral-80: theme('colors.neutral.80'); // Medium dark shade
  --amplify-colors-neutral-90: theme('colors.neutral.90'); // Dark shade
  --amplify-colors-neutral-100: theme('colors.neutral.100'); // Darkest shade

  --amplify-colors-tertiary-10: theme('colors.tertiary.10'); // Lightest shade
  --amplify-colors-tertiary-20: theme('colors.tertiary.20'); // Light shade
  --amplify-colors-tertiary-40: theme('colors.tertiary.40'); // Medium light shade
  --amplify-colors-tertiary-60: theme('colors.tertiary.60'); // Medium shade
  --amplify-colors-tertiary-80: theme('colors.tertiary.80'); // Medium dark shade
  --amplify-colors-tertiary-90: theme('colors.tertiary.90'); // Dark shade
  --amplify-colors-tertiary-100: theme('colors.tertiary.100'); // Darkest shade
  
  --amplify-fonts-default-variable: theme('fontFamily.sans'); // Use Tailwind's sans font family
  --amplify-fonts-default-static: theme('fontFamily.sans'); // Use Tailwind's sans font family

  --amplify-colors-font-active: var(--amplify-colors-primary-100);
  --amplify-colors-font-interactive: var(--amplify-colors-primary-100);
  --amplify-colors-font-hover: var(--amplify-colors-primary-100);
  --amplify-colors-font-secondary: var(--amplify-colors-secondary-100);
  --amplify-colors-font-focus: var(--amplify-colors-primary-100);

  --amplify-components-button-hover-border-color:	var(--amplify-colors-neutral-60); 
  --amplify-components-button-hover-background-color:	var(--amplify-colors-neutral-10);	
  --amplify-components-button-link-hover-background-color:	var(--amplify-colors-neutral-10);	
  --amplify-components-button-link-focus-background-color:	var(--amplify-colors-neutral-10);	

  --amplify-components-button-primary-background-color: var(--amplify-colors-primary-100);
  --amplify-components-button-primary-focus-background-color: var(--amplify-colors-primary-90);
  --amplify-components-button-primary-hover-background-color: var(--amplify-colors-primary-90);

  --amplify-components-authenticator-router-border-width: none; /* No border */
  --amplify-components-authenticator-router-border-style: none; /* Remove border style */
  --amplify-components-authenticator-router-box-shadow: none;

}

@import '~@aws-amplify/ui/dist/styles.css';

input::placeholder {
  font: 1rem;
}

.federated-sign-in-container {
  flex-direction: column;
  padding: 0 0 1rem 0;
}

.data-amplify-authenticator {
  border-radius: 1.5rem; // Adjust the value as needed for desired roundness
}

.federated-sign-in-button {
  display: block;
}

.federated-sign-in-button-row {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// Add dark mode styles
.dark {
  :root {
    --amplify-colors-primary-10: #4F46E5; // Indigo
    --amplify-colors-primary-20: #6B73E5; // Light Indigo
    --amplify-colors-primary-40: #3B3F8C; // Dark Indigo
    --amplify-colors-font-active: #fff; // White for active font
    --amplify-colors-font-secondary: #fff; // White for secondary font
    --amplify-colors-background: #1a1a1a; // Dark background
    // Add other dark mode variables as needed
  }

  .federated-sign-in-container {
    @apply bg-gray-800 text-white; // Dark background and white text
  }

  .federated-sign-in-button {
    @apply bg-indigo-600; // Keep primary button color
  }
}
